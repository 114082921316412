:root {
  --pink: #c11bdc;
  --white: #ffffff;
  --dark: #1d2028;
  --grey: #8d8f93;
  --bg: #eaeaea;
}

.centered {
  max-width: 1266px;
  margin-left: auto;
  margin-right: auto;
}

.calendar {
  display: grid;
  grid-template-columns: 346px 1fr;
  height: 600px;
}

.calendar-title {
  text-align: center;
  color: var(--white);
}

.schedule-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

.modal-subtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

div#big-calendar {
  padding: 50px;
}

.react-calendar {
  background: var(--dark);
  color: var(--white);
  padding: 25px;
}

.schedule {
  height: 600px;
  overflow-y: auto;
  padding: 20px;
  background-color: var(--bg);
}

.react-calendar__navigation__label {
  color: var(--white);
}

.react-calendar__navigation__arrow {
  color: var(--pink);
}

.react-calendar__tile {
  color: var(--white);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--grey);
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
}

abbr {
  text-decoration: none;
}

.react-calendar__navigation__label:focus {
  background-color: inherit !important;
}
.react-calendar__navigation__label:disabled {
  background-color: inherit !important;
}

.react-calendar__tile {
  background-color: inherit;
}

.react-calendar__tile {
  background-color: inherit;
}

.react-calendar__tile--now {
  color: var(--pink);
}

.react-calendar__tile:hover,
.react-calendar__tile:focus {
  background: var(--pink) !important;
  border-radius: 30px;
  color: white !important;
}

.react-calendar__tile--active,
.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus {
  background: var(--pink) !important;
  border-radius: 30px;
  color: white !important;
}
.react-calendar__navigation__label:hover {
  background: var(--pink) !important;
  border-radius: 30px;
}

.calendar-event {
  display: grid;
  align-items: center;
  grid-template-columns: 65px 49px 1fr 109px;
  height: 49px;
  margin-bottom: 5px;
  background: var(--white);

  .time {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 13px;
    padding-right: 13px;
    color: var(--white);
    background: var(--dark);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

    .hours-minutes {
      margin-right: 1px;
    }

    .am-pm {
      font-size: 9px;
    }
  }

  .icon {
    padding-left: 3px;
  }

  .title {
    padding-left: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
  }
}

.calendar-button {
  background: var(--pink);
  height: 49px;
  width: 109px;
  align-items: center;
  text-align: center;
  display: grid;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--white);
  border-width: 0;
  border-color: transparent;
  cursor: pointer;
}

.react-calendar button {
  margin-bottom: 18px;
}

.react-calendar__navigation {
  border-bottom: 1px solid #473e3e;
  margin-bottom: 30px;
}

@media screen and (max-width: 899px) {
  .calendar {
    grid-template-columns: 1fr;
    margin: 24px;
  }
  .react-calendar {
    width: 100%;
  }

  .schedule {
    margin-bottom: 20px;
    height: unset;
    min-height: 150px;
    max-height: 600px;
  }
}

@media screen and (max-width: 520px) {
  .calendar-button {
    width: 65px;
  }
  .calendar-event {
    grid-template-columns: 65px 49px 1fr 65px;
  }
}

@media screen and (max-width: 410px) {
  .react-calendar__navigation__label__labelText {
    font-size: 16px;
  }
  .calendar-event .title {
    font-size: 13px;
    line-height: 15px;
  }
}


